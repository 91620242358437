
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";
import { ElMessageBox } from "element-plus";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "RegisterPlan",
  components: {},
  setup() {
    let data = reactive<any>({
      HospitalTableData: [],
      HospitalSearch: "",
      DepartmentTableData: [],
      DepartmentSearch: "",
      DoctorTableData: [],
      DoctorSearch: "",
      params: {
        HospitalId: "",
        HospitalName: "",
        DepId: "",
        DepName: "",
        DocId: "",
        DocName: "",
        PatientName: "",
        PatientMobile: "",
        OutcallDate: "",
        OutcallDaypart: "",
        PTime: "",
        PatientIdCard: "",
      },
      calendar: [],
      calendarRow: 0,
      orderList: [],
    });

    const RegisterMsg = (row: any) => {
      ElMessageBox.alert(row.RegisterMsg, "短信", {
        confirmButtonText: "确定",
        // callback: (action: Action) => {
        //   // ElMessage({
        //   //   type: "info",
        //   //   message: `action: ${action}`,
        //   // });
        // },
      });
    };

    const getPreOrderStatus = (row: any) => {
      var rr = "";
      switch (row.PreOrderStatus) {
        case 0:
          rr = "未确认";
          break;
        case 1:
          rr = "院方受理";
          break;
      }
      return rr;
    };

    const getScheduleDate = (row: any) => {
      var rr = "";
      switch (row.ScheduleDayPart) {
        case 0:
          rr = " 上午 ";
          break;
        case 1:
          rr = " 下午 ";
          break;
        case 2:
          rr = " 晚上 ";
          break;
        case 9:
          rr = " 全天 ";
          break;
      }
      rr = row.ScheduleDate.substring(0, 10) + rr + row.PTime;
      return rr;
    };

    const CopyReg = (row: any) => {
      var req = {
        AutoId: row.AutoId,
        HospitalId: row.HospitalId,
      };
      Api.Api2.PlanCopyReg(req).then((res: any) => {
        console.info(res.data);
        if (res.code == "0") {
          PlanOrder();
          Api.Msg.success(res.data);
        } else {
          Api.Msg.warning(res.data);
        }
      });
    };

    const PlanOrder = () => {
      Api.Api2.PlanOrder(1).then((res: any) => {
        console.info(res.data);
        if (res.code == "0") {
          data.orderList = res.data;
        } else {
          data.orderList = [];
        }
      });
    };

    const Clear = () => {
      data.params.OutcallDate = "";
      data.params.PTime = "";
      data.params.OutcallDaypart = "";
    };

    const PlanAdd = () => {
      Api.Api2.PlanAdd(data.params).then((res: any) => {
        if (res.code == "0") {
          Clear();
          PlanOrder();
          Api.Msg.success(res.data);
        } else {
          Api.Msg.warning(res.data);
        }
      });
    };

    const setForm = (date: any) => {
      data.params.OutcallDate = date;
    };

    const calendarList = (i: any) => {
      console.info(i);
      var list = [];
      for (let index = 0; index < data.calendar.length; index++) {
        if (index >= i * 7 && index < (i + 1) * 7)
          list.push(data.calendar[index]);
      }
      console.info(list);
      return list;
    };

    const getFormatTimes = (day: any) => {
      var myDate = new Date();
      myDate.setDate(myDate.getDate() + day);
      var wk = myDate.getDay();
      var yy = String(myDate.getFullYear());
      var mm =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1;
      var dd = String(
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate()
      );
      var nowDate = yy + "-" + mm + "-" + dd;
      return {
        wk: wk,
        date: nowDate,
      };
    };

    const PlanScheme = (row: any) => {
      data.params.DocId = row.DocId;
      data.params.DocName = row.DocName;
      data.calendar = [];
      var add = 0;
      var wk = getFormatTimes(add).wk;
      if (wk != 0) {
        for (let index = -1 * wk + 1; index <= 0; index++) {
          data.calendar.push({
            date: getFormatTimes(index).date,
          });
        }
      }
      Api.Api2.PlanScheme(data.params).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          if (res.data.length > 0) {
            var tdate = new Date();
            tdate.setDate(tdate.getDate() + 1);
            var sp = res.data[res.data.length - 1].SchemeDate.split("-");
            var odate = new Date();
            odate.setFullYear(sp[0], sp[1] - 1, sp[2]);
            while (tdate <= odate) {
              add++;
              data.calendar.push({
                date: getFormatTimes(add).date,
              });
              tdate.setDate(tdate.getDate() + 1);
            }
            for (let index = 0; index < res.data.length; index++) {
              var findVal = data.calendar.find((item: any) => {
                return item.date === res.data[index].SchemeDate;
              });

              if (findVal.SchemeDaypart)
                findVal.SchemeDaypart.push({
                  SchemeDaypart: res.data[index].SchemeDaypart,
                  Flag: res.data[index].Flag,
                });
              else
                findVal.SchemeDaypart = [
                  {
                    SchemeDaypart: res.data[index].SchemeDaypart,
                    Flag: res.data[index].Flag,
                  },
                ];
            }

            odate.setDate(odate.getDate() + 10);
            while (tdate <= odate) {
              add++;
              data.calendar.push({
                date: getFormatTimes(add).date,
                button: true,
              });
              tdate.setDate(tdate.getDate() + 1);
            }
          }
        } else {
          data.calendar = [];
        }
        data.calendarRow = Math.ceil(data.calendar.length / 7);
        console.info(data.calendar);
        console.info(data.calendarRow);
      });
    };

    const getHospitalName = (data: any) => {
      return data.HospitalName + (data.Docking ? " -- 直连" : "");
    };

    const getDoctorName = (data: any) => {
      return data.DocName + (data.DocTitle == "" ? "" : " -- " + data.DocTitle);
    };

    const PlanHospital = () => {
      Api.Api2.PlanHospital().then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.HospitalTableData = res.data;
          data.DepartmentTableData = [];
          data.DoctorTableData = [];
          data.calendar = [];
          data.calendarRow = 0;
          Clear();
        }
      });
    };

    const PlanDepartment = (row: any) => {
      data.params.HospitalId = row.HospitalID;
      data.params.HospitalName = row.HospitalName;
      Api.Api2.PlanDepartment(data.params).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.DepartmentTableData = res.data;
          data.DoctorTableData = [];
          data.calendar = [];
          data.calendarRow = 0;
          Clear();
        }
      });
    };

    const PlanDoctor = (row: any) => {
      data.params.DepId = row.DepId;
      data.params.DepName = row.DepName;
      Api.Api2.PlanDoctor(data.params).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.DoctorTableData = res.data;
          data.calendar = [];
          data.calendarRow = 0;
          Clear();
        }
      });
    };

    onMounted(() => {
      PlanHospital();
      PlanOrder();
    });

    return {
      ...toRefs(data),
      RegisterMsg,
      getPreOrderStatus,
      getScheduleDate,
      CopyReg,
      PlanAdd,
      setForm,
      calendarList,
      PlanScheme,
      getHospitalName,
      getDoctorName,
      PlanDepartment,
      PlanDoctor,
    };
  },
});
